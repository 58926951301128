import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: false,
  },
  mutations: {
    SET_AUTH(state, bool) {
      state.auth = bool
    },
  },
  actions: {
    setAuth({ commit }, bool) {
      commit('SET_AUTH', bool)
    },
  },
  modules: {},
})

<template>
  <div v-if="this.$route.name === 'Courses'" class="search">
    <input class="input" placeholder="Search" type="text" />
    <v-icon color="grey darken-1"> mdi-magnify </v-icon>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

.search {
  width: 500px;
  height: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  -webkit-box-shadow: 0px 3px 3px 2px rgba(34, 60, 80, 0.4);
  -moz-box-shadow: 0px 3px 3px 2px rgba(34, 60, 80, 0.4);
  box-shadow: 0px 3px 3px 2px rgba(34, 60, 80, 0.4);
}

.input {
  width: 100%;
}

#test {
  margin: 0;
}
</style>

<template>
  <v-app>
    <v-app-bar app color="teal accent-4">
      <router-link to="/" style="text-decoration: none; color: inherit">
        <div class="d-flex align-center">
          <h1 class="white--text">SDA</h1>
        </div>
      </router-link>

      <v-spacer></v-spacer>
      <Search />
      <v-spacer></v-spacer>

      <v-btn class="mr-2 white--text" router-link to="/about" text>
        <span>About</span>
      </v-btn>
      <v-btn class="mr-2 white--text" router-link to="/courses" text>
        <span>Courses</span>
      </v-btn>
      <v-btn
        v-if="$store.state.auth"
        class="mr-2 white--text"
        router-link
        to="/my-courses"
        text
      >
        <span>My courses</span>
      </v-btn>
      <v-btn
        v-if="$store.state.auth"
        class="mr-2 white--text"
        router-link
        to="/create"
        text
      >
        <span>Create</span>
      </v-btn>
      <v-btn
        v-if="!$store.state.auth"
        class="mr-2 white--text"
        router-link
        to="/login"
        text
      >
        <span>Login</span>
      </v-btn>
      <v-btn
        router-link
        to="/login"
        v-if="$store.state.auth"
        class="mr-2 white--text"
        text
        @click="$store.dispatch('setAuth', false)"
      >
        <span>Logout</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer v-if="this.$route.name !== 'Login'" padless>
      <v-card
        color="teal accent-4"
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        style="width: 100%"
      >
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          SDA is an all in one platform for completing courses on different
          topics. Get into something or delve deeper easily by choosing the
          difficulty level. Our application makes it simple to find courses
          which will be most useful to you.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>SDA</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import Search from './components/Search.vue'
export default {
  components: { Search },
  name: 'App',

  data: () => ({
    icons: ['mdi-facebook', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram'],
  }),
}
</script>
